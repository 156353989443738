import React from 'react';
import PropTypes from 'prop-types';

function ReturnQuestion(props) {
  return (
    <div className="question">{props.questionContent}</div>
  )
}

ReturnQuestion.propTypes = {
  questionContent: PropTypes.string.isRequired
};

export default ReturnQuestion;
