import React, {Component} from 'react';
import logo from './logo.svg';
import './App.css';
import marifoon1Questions from './api/marifoon1Questions';
import Quiz from './components/Quiz';
import Result from './components/Result';
import ReturnExplanation from './components/Explains';
import ReturnScores from './components/Scores';

class App extends Component {
  constructor(props) {
    super(props);
    this.QuestionScoreCount = 0;
    this.correctCount = 0;
    this.incorrectCount = 0;
    this.reward = "";

    this.state = {
      counter: 0,
      questionId: 1,
      question: '',
      answerOptions: [],
      answer: '',
      answersCount: {
        Incorrect: 0,
        Correct: 0
      },
      explain: '',
      result: '',
      raceIsOver: 'false',
      resultScore: 0
    }
   this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
  }

  componentWillMount() {
    const shuffledAnswerOptions = marifoon1Questions.map(question =>
       this.shuffleArray(question.answers)
    );
    this.setState({
      question: marifoon1Questions[0].question,
      explain: marifoon1Questions[0].explain,
      answerOptions: shuffledAnswerOptions[0],
      questionScore: marifoon1Questions[0].questionScore
    });
  }

  handleAnswerSelected(event) {
   this.setUserAnswer(event.currentTarget.value);
   if (event.currentTarget.value === "Correct") {
     this.setQuestionScores();
     this.correctCount = this.correctCount + 1;
     this.reward = "./img/good1.png";
   } else {
     this.incorrectCount = this.incorrectCount + 1;
     this.reward = "./img/wrong1.png";
   }

   // console.log("Event value: "+event.currentTarget.value);
   // console.log("questionScore: " + this.state.questionScore);
   // console.log("questionScoreCount: " + this.QuestionScoreCount);

   if (this.state.questionId < marifoon1Questions.length) {
     setTimeout(() => this.setNextQuestion(), 300);
   } else {
     this.reward = "./img/done1.png";
     setTimeout(() => this.setResults(this.QuestionScoreCount), 300);
     //setTimeout(() => this.setResults(this.getResults()), 300);
   }
  }

  setResults(result) {
    if (result > 22) {
      this.setState({ result: 'result' });
      this.setState({raceIsOver: 'true'});
    } else {
      this.setState({ result: 'niet voldoende' });
      this.setState({raceIsOver: 'true'});
    }
  }
  //
  // getResults() {
  //   const answersCount = this.state.answersCount;
  //   const answersCountKeys = Object.keys(answersCount);
  //   const answersCountValues = answersCountKeys.map(key => answersCount[key]);
  //   const maxAnswerCount = Math.max.apply(null, answersCountValues);
  //   return answersCountKeys.filter(key => answersCount[key] === maxAnswerCount);
  // }

  setUserAnswer(answer) {
  this.setState((state, props) => ({
    answersCount: {
      ...state.answersCount,
      [answer]: state.answersCount[answer] + 1
    },
    answer: answer
  }));
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: marifoon1Questions[counter].question,
      explain: marifoon1Questions[counter].explain,
      answerOptions: marifoon1Questions[counter].answers,
      answer: '',
      raceIsOver: 'false',
      questionScore: marifoon1Questions[counter].questionScore
    });
  }

  // getQuestion(props) {
  //   return (
  //     <ReturnQuestion
  //       questionContent={this.state.question} />
  //   )
  // }

  getExplanation(props) {
    if (this.state.raceIsOver === 'false') {
      return (
        <ReturnExplanation
          explanationContent={this.state.explain} />
      )
    } else {
      return (
        <ReturnExplanation
          explanationContent="Einde examen" />
      )
    }
  }

  setQuestionScores(props) {
    this.QuestionScoreCount = this.QuestionScoreCount + this.state.questionScore;
  }

  getQuestionScores(props) {
    return (
    <ReturnScores
      scoreCount={this.QuestionScoreCount}
      incorrectAnswerCount={this.incorrectCount}
      correctAnswerCount={this.correctCount}
      reward={this.reward}/>
    )
  }

  shuffleArray(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  return array;
  }

  renderQuiz() {
  return (
    <Quiz
      answer={this.state.answer}
      answerOptions={this.state.answerOptions}
      questionId={this.state.questionId}
      question={this.state.question}
      questionTotal={marifoon1Questions.length}
      onAnswerSelected={this.handleAnswerSelected}
    />
  );
  }

  renderResult(props) {
    if (this.state.result === 'niet voldoende') {
      return (
        <Result
              quizResult={this.state.result}
              resultScore={this.QuestionScoreCount} />
            );
    } else {
      return (
        <Result
              quizResult='voldoende'
              resultScore={this.QuestionScoreCount} />
            );
    }
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Pura Vida Quiz</h2>
        </div>
        <div className="scores">
          {this.getQuestionScores()}
        </div>
        {this.state.result ? this.renderResult() : this.renderQuiz()}
          <div className="explanation">
          Uitleg:
            {this.getExplanation()}
          </div>
      </div>
    );
  }
}

export default App;
