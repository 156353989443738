import React from 'react';
import PropTypes from 'prop-types';

function ReturnScores(props) {
  return (
    <div className="container">
      <div className="scoretxt">
        <h3>Score: {props.scoreCount}</h3>
        Antwoorden goed: {props.correctAnswerCount} <br />
        Antwoorden fout: {props.incorrectAnswerCount} <br />
        <img src={props.reward} />
      </div>
    </div>
  )
}

ReturnScores.propTypes = {
  scoreCount: PropTypes.number.isRequired,
  correctAnswerCount: PropTypes.number.isRequired,
  incorrectAnswerCount: PropTypes.number.isRequired
};

export default ReturnScores;
