var marifoon1Questions = [
  {
	question: "Bij de opstelling van de loodaccubatterijen aan boord is het van groot belang dat:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "een juiste relatieve vochtigheid in de ruimte aanwezig is"
	  },
	  {
		  type: "Incorrect2",
		  content: "de batterijen niet te hoog gestapeld worden"
	  },
	  {
		  type: "Correct",
		  content: "de ruimte waarin de accubatterijen zijn opgesteld voldoende worden geventileerd"
	  }
	],
	explain: "Ruimtes waar zich loodaccubatterijen bevinden dienen altijd goed geventileerd te worden.",
  questionScore: 1
  },
  {
	question: "De afkorting DSC staat voor",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Digital Safety Calling"
	  },
	  {
		  type: "Incorrect2",
		  content: "Digital Selective Communication"
	  },
	  {
		  type: "Correct",
		  content: "Digital Selective Calling"
	  }
	],
	explain: "Een binnenvaartmarifoon is bedoeld om te luisteren en te spreken; dat noemen we radiotelefonie. Met een zeevaartmarifoon kun je ook geschreven berichten ontvangen en verzenden. Dat noemen we Digital Selective Calling (DSC). Het gebruik van DSC is op het binnenwater niet toegestaan.",
  questionScore: 1
  },
  {
	question: "Het radioverkeer tussen 2 schepen onderling noemt men?",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "intraship verkeer"
	  },
	  {
		  type: "Correct",
		  content: "Intership verkeer"
	  },
	  {
		  type: "Incorrect2",
		  content: "openbaar verkeer"
	  }
	],
	explain: "Het radioverkeer tussen 2 schepen onderling wordt intership verkeer genoemd.",
  questionScore: 1
  },
  {
	question: "De VHF-kanalen 23 en 83 worden door de Nederlandse Kustwacht gebruikt om:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "bij de coördinatie van SAR operaties"
	  },
	  {
		  type: "Incorrect2",
		  content: "uit te luisteren bij noodverkeer als VHF-kanaal 16 bezet is"
	  },
	  {
		  type: "Correct",
		  content: "veiligheidsberichten op uit te zenden"
	  }
	],
	explain: "De Nederlandse kustwacht gebruikt de VHF-kanalen 23 en 83 om veiligheidsberichten uit te zenden",
  questionScore: 2
  },
  {
	question: "Bij calamiteiten (bijv. brand) op de IJssel moet men aan boord gebruik maken van het:",
	answers: [
		{
			type: "Incorrect1",
			content: "spoedsein"
		},
		{
			type: "Incorrect2",
			content: "veiligheidssein"
		},
		{
			type: "Correct",
			content: "noodsein"
		}
	],
	explain: "Bij calamiteiten moet men gebruik maken van het noodsein",
  questionScore: 2
  },
    {
	question: "Rondzingen kan ontstaan:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "door geluiden van buiten, die door de ingebouwde luidspreker worden versterkt."
	  },
	  {
		  type: "Incorrect2",
		  content: "doordat de ingebouwde luidspreker als microfoon gaat werken."
	  },
	  {
		  type: "Correct",
		  content: "doordat het volume van de luidspreker te hoog staat."
	  }
	],
	explain: "Als het volume van de luidspreker te hoog staat kan rondzingen ontstaan",
  questionScore: 1
	},
    {
	question: "Welk sein moet worden gebruikt als men in nood is en onmiddellijk hulpvereist is:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "HELLUP HELLUP HELLUP."
	  },
	  {
		  type: "Incorrect2",
		  content: "PAN PAN 3X."
	  },
	  {
		  type: "Correct",
		  content: "MAYDAY 3X."
	  }
	],
	explain: "De meeste 'sein' berichten beginnen met 3X het type sein te noemen. Bij dringende nood gebruik je MAYDAY, MAYDAY, MAYDAY",
  questionScore: 2
	},
    {
	question: "De officiele rekeneenheid die binnen het internationale maritieme radioverkeer gebruikt wordt is de:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "GoldFranc (GFC)."
	  },
	  {
		  type: "Incorrect2",
		  content: "Euro (€)."
	  },
	  {
		  type: "Correct",
		  content: "Special Drawing Rights (SDR)."
	  }
	],
	explain: "De officiele rekeneenheid die wordt gebruikt voor internationaal maritiem radioverkeer is SDR Special Drawing Rights",
  questionScore: 1
	},
    {
	question: "U vaart op de Rijn en wilt hulp inroepen van andere schepen. De oproep dient plaats te vinden op marifoonkanaal:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "16."
	  },
	  {
		  type: "Incorrect2",
		  content: "10."
	  },
	  {
		  type: "Correct",
		  content: "30."
	  }
	],
	explain: "Om hulp in te roepen op de Rijn dien je gebruik te maken van kanaal 30",
  questionScore: 2
	},
    {
	question: "Met de squelch-instelling op het bedieningspaneel van een VHF-installatie regelt men:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "De verhouding tussen atmosferische ruis en het ontvangen gesproken woord."
	  },
	  {
		  type: "Incorrect2",
		  content: "De geluidssterkte van het ontvangen signaal dat uit de luidspreker komt."
	  },
	  {
		  type: "Correct",
		  content: "De drempelwaarde waarboven ontvangen signalen wel, en ruis niet worden doorgelaten."
	  }
	],
	explain: "Met de squelch-instelling kan de ruis onderdrukking worden ingesteld. Een te hoge squelch instelling kan ervoor zorgen dat zwakke stations niet meer worden ontvangen",
  questionScore: 1
	},
    {
	question: "In de maritieme radiocommunicatie verstaat men onder laag vermogen (low power) een afgegeven zendvermogen van:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "1,0 - 1,5 watt."
	  },
	  {
		  type: "Incorrect2",
		  content: "Maximaal 0,5 watt."
	  },
	  {
		  type: "Correct",
		  content: "0,5 - 1,0 watt."
	  }
	],
	explain: "Laag vermogen (low power) is tussen 0,5 en 1 watt. Ook wel gereduceerd zendvermogen genoemd. High power is tussen 6 en 25 watt. In Nederland is het op binnenwateren niet toegestaan om handmatig van laag naar hoog vermogen te schakelen.",
  questionScore: 2
	},
    {
	question: "Het gebruik van de 'dual watch' functie is op de binnenwateren:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Toegestaan."
	  },
	  {
		  type: "Incorrect2",
		  content: "Toegestaan met een zeevaart marifoon."
	  },
	  {
		  type: "Correct",
		  content: "Niet toegestaan."
	  }
	],
	explain: "Dual watch is het min of meer gelijktijdig uitluisteren op twee kanalen, In Nederland is dat niet toegestaan en is deze knop afwezig of buiten werking gesteld.",
  questionScore: 1
	},
    {
	question: "Op de binnenwateren is het gebruik van de functie scanning:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Toegestaan."
	  },
	  {
		  type: "Incorrect2",
		  content: "Toegestaan op speciaal verzoek."
	  },
	  {
		  type: "Correct",
		  content: "Niet toegestaan."
	  }
	],
	explain: "Bij de functie scanning zoekt de marifoon naar actieve zenders en selecteerd het actieve kanaal. Het gebruik van de functie scanning is op het binnenwater niet toegestaan.",
  questionScore: 1
	},
    {
	question: "Het gebruik van UHF-portofoons aan boord van Nederlandse schepen is:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Alleen toegestaan op schepen met gevaarlijke lading."
	  },
	  {
		  type: "Incorrect2",
		  content: "Niet toegestaan."
	  },
	  {
		  type: "Correct",
		  content: "Uitsluitend toegestaan voor intraship verkeer."
	  }
	],
	explain: "Intraship verkeer is onderlinge communicatie aan boord van het schip. Een UHF portofoon wordt op grotere schepen gebruikt voor communicatie binnen de boot.",
  questionScore: 1
	},
    {
	question: "De voorgeschreven lengte van een marifoon-antenne is ongeveer:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "7 meter."
	  },
	  {
		  type: "Incorrect2",
		  content: "3,5 meter."
	  },
	  {
		  type: "Correct",
		  content: "1 meter."
	  }
	],
	explain: "De voorgeschreven lengte van een marifoon antenne is ongeveer 1 meter. De antenne moet verticaal polariserend zijn (Rondstralende).",
  questionScore: 1
	},
    {
	question: "Voor de verbinding tussen marifoon en antenne gebruikt men een:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Een coaxkabel."
	  },
	  {
		  type: "Incorrect2",
		  content: "Een geisoleerde koperen draad van de juiste diameter."
	  },
	  {
		  type: "Correct",
		  content: "Een coaxiale kabel van 50 Ohm."
	  }
	],
	explain: "Voor de verbinding tussen marifoon en antenne dien je een coaxiale kabel van 50 Ohm (impedantie) te gebruiken.",
  questionScore: 1
	},
    {
	question: "Onder propagatie in het radiovekeer wordt verstaan:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Het verplicht uitluisteren op een frequentie."
	  },
	  {
		  type: "Incorrect2",
		  content: "Het uitgestraald vermogen."
	  },
	  {
		  type: "Correct",
		  content: "De wijze waarop radiogolven zich voortbewegen."
	  }
	],
	explain: "De wijze waarop radiogolven zich voortbewegen noemen we propagatie. De propagatie van de VHF-band is vrijwel rechtlijningen worden door de ionosfeer niet gereflecteerd.",
  questionScore: 1
	},
    {
	question: "Het zendbereik van een VHF-installatie wordt in hoofdzaak bepaald door:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Het ingestelde zendvermogen."
	  },
	  {
		  type: "Incorrect2",
		  content: "De lengte van de antenne."
	  },
	  {
		  type: "Correct",
		  content: "De hoogte van de antenne."
	  }
	],
	explain: "Omdat VHF-radiogolven zich rechtlijning voortbewegen en niet gereflecteerd worden wordt het zendbereik van VHF in hoofdzaak bepaalt door de hoogte van de antenne.",
  questionScore: 2
	},
    {
	question: "Het maximale zendbereik van een VHF-radioinstallatie van schip tot schip bedraagt op zee:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "2 zeemijl."
	  },
	  {
		  type: "Incorrect2",
		  content: "200 zeemijl."
	  },
	  {
		  type: "Correct",
		  content: "20 zeemijl."
	  }
	],
	explain: "Door de kromming van de aarde is het maximale zendbereik van VHF op zee ongeveer 20 zeemijl.",
  questionScore: 2
	},
    {
	question: "In een jachthaven kunnen jachten elkaar bereiken via:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "Duplexmarinfoonkaneel 31."
	  },
	  {
		  type: "Incorrect2",
		  content: "Geen enkel kanaal, de marifoon dient uitgeschakeld te zijn in de jachthaven."
	  },
	  {
		  type: "Correct",
		  content: "Simplexmarifoonkanaal 77."
	  }
	],
	explain: "Kanaal 31 is het havenkanaal, in de jachthaven kunnen jachten onderling communiceren via kanaal 77, kanaal 77 is bekend als het sociaal kanaal.",
  questionScore: 2
	},
    {
	question: "Schepen die onderling duplex willen werken met de VHF, kunnen dat:",
	answers: [
	  {
		  type: "Incorrect1",
		  content: "op de intershipkanelen."
	  },
	  {
		  type: "Incorrect2",
		  content: "altijd op de intershipduplexkanalen."
	  },
	  {
		  type: "Correct",
		  content: "Alleen als men gebruik maakt van een relaystation."
	  }
	],
	explain: "Simplex wordt gebruikt voor de communicatie tussen schepen onderling (ship - schipverkeer). Duplex kan gebruikt worden door aan walstation. Als 2 schepen onderling duplex willen communiceren kan dat via een relay (wal) station. De schepen moeten dit eerst afstemmen met het walstation",
  questionScore: 1
	}
];

export default marifoon1Questions;
