import React from 'react';
import PropTypes from 'prop-types';

function ReturnExplanation(props) {
  return (
    <h5 className="explanations">{props.explanationContent}</h5>
  )
}

ReturnExplanation.propTypes = {
  explanationContent: PropTypes.string.isRequired
};

export default ReturnExplanation;
